import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }
  getAlertas() {
    return this.http.get(`${environment.api}/Alertas`);
  }

  getOperadores() {
    return this.http.get(`${environment.api}/Alertas/operadores`);
  }

  getAlerta(id) {
    return this.http.post(`${environment.api}/Alertas/${id}`, { id: id });
  }

  getHistory(model) {
    return this.http.post(`${environment.api}/Alertas/history`, model);
  }

  marcarComoResulto(id, obs, resuelto) {
    return this.http.post(`${environment.api}/Alertas/${id}/resuelto`, { id: id, observaciones: obs, resuelto: resuelto });
  }

}
